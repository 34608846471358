import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClientWrapperService} from "../../../common/service/http-client-wrapper.service";
import {ApplicantStatusOutDto} from "./candidate-has-job.service";
import {AttachmentMinOutDto, AttachmentOutDto} from "../../../common/component/upload/common/service/document.service";
import {ApplicantOutDto} from "./applicant.service";

@Injectable({
    providedIn: 'root'
})
export class CandidateHasJobApplicationService {

    private candidateUrl = '/candidates';
    private jobApplicationUrl = '/jobApplications';

    constructor(private httpClientWrapperService: HttpClientWrapperService) {
    }

    findAll(candidateId: string): Observable<CandidateHasJobApplicationOutDto[]> {
        return this.httpClientWrapperService.get(`${this.candidateUrl}/${candidateId}${this.jobApplicationUrl}`);
    }

    find(candidateId: string, candidateHasJobApplicationId: string): Observable<CandidateHasJobApplicationOutDto> {
        return this.httpClientWrapperService.get(`${this.candidateUrl}/${candidateId}${this.jobApplicationUrl}/${candidateHasJobApplicationId}`);
    }

    patch(candidateId: string, candidateHasJobApplicationId: string, candidateHasJobApplicationPatchInDto: CandidateHasJobApplicationPatchInDto): Observable<CandidateHasJobApplicationOutDto> {
        return this.httpClientWrapperService.patch<CandidateHasJobApplicationOutDto>(`${this.candidateUrl}/${candidateId}${this.jobApplicationUrl}/${candidateHasJobApplicationId}`, candidateHasJobApplicationPatchInDto);
    }

    delete(candidateId: string, candidateHasJobApplicationId: string): any {
        return this.httpClientWrapperService.delete(`${this.candidateUrl}/${candidateId}${this.jobApplicationUrl}${candidateHasJobApplicationId}`);
    }
}

export class CandidateHasJobApplicationPatchInDto {
    applicantId: string;
    applied?: boolean;
    saved?: boolean;
}

export class CandidateHasJobApplicationOutDto {
    id: string;
    createdAt: string;
    applicantOutDto: ApplicantOutDto;
}

export class ApplicantHasFieldValueDto {
    value: string;
}

export class ConfigurationDocument {
    name: string;
}

export class ConfigurationDocumentOutDto extends ConfigurationDocument {
    id: string;
    createdAt: string;
    modifiedAt: string;
}

export class ApplicantHasCommentDto {
    comment: string;
}

export class ApplicantHasHiringRoundDto {
    // TODO : will add it later HIRING_ROUND_STATUS
    status: any;
}

export class ApplicantHasHiringRoundOutDto extends ApplicantHasHiringRoundDto {
    id: string;
    hiringRoundSummary: string;
    // TODO : Will add it laterJobPostHasHiringRoundOutDto
    jobPostHasHiringRoundOutDto: any;
}

export class ApplicantHasCommentOutDto extends ApplicantHasCommentDto {
    id: string;
    createdAt: string;
    // TODO: will add it JobPostHasApplicationFormOutDto later
    commentBy: any;
    commentType: string;
    applicantHasHiringRoundOutDto: ApplicantHasHiringRoundOutDto;
    applicantStatusOutDto: ApplicantStatusOutDto;
    applicantStatus: APPLICANT_STATUS_TYPE;
}

export enum APPLICATION_DOCUMENT_TYPE {
    COVER_LETTER = 'COVER_LETTER',
    RESUME = 'RESUME',
    WEBSITE = 'WEBSITE'
}

export enum APPLICANT_STATUS_TYPE {
    DRAFT = 'DRAFT',
    APPLIED = 'APPLIED',
    SHORTLISTED = 'SHORTLISTED',
    IN_REVIEW = 'IN_REVIEW',
    HIRED = 'HIRED',
    REJECTED = 'REJECTED',
    WITHDRAWN = 'WITHDRAWN',
    DECLINED = 'DECLINED',
    INVITED = 'INVITED',
    CUSTOM = 'CUSTOM',
    EXPIRED = 'EXPIRED',
    REMAINING = 'REMAINING',
    NEW_POST = 'NEW-POST'
}

export enum APPLICATION_STATUS_TYPE_DISPLAY {
    DRAFT = 'Draft',
    APPLIED = 'Applied',
    SHORTLISTED = 'Shortlisted ⭐️',
    IN_REVIEW = 'In-Review',
    HIRED = 'Hired',
    REJECTED = 'Rejected',
    WITHDRAWN = 'Withdrawn',
    INVITED = 'Invited',
    DECLINED = 'Declined',
    CUSTOM = 'Custom'
}

// NOTE: used for displayName
export enum APPLICATION_STATUS_DISPLAY_NAME {
    INVITED = 'Invited',
    DECLINED = 'Declined',
    OFFERED = 'Offered',
    OFFER_ACCEPTED = 'Offer Accepted',
    OFFER_DECLINED = 'Offer Declined',
}

//Note only for frontend
export enum APPLICATION_STATUS_DISPLAY_VALUE {
    DRAFT = 'Draft',
    APPLIED = 'Applied',
    SHORTLISTED = 'Shortlisted ⭐️',
    IN_REVIEW = 'In-Review',
    HIRED = 'Hired',
    REJECTED = 'Rejected',
    WITHDRAWN = 'Withdraw',
    DECLINED = 'Decline'
}

export enum APPLICATION_STATUS_TYPE_COLOR {
    DRAFT = 'draft',
    APPLIED = 'applied',
    SHORTLISTED = 'shortlisted',
    IN_REVIEW = 'in-review',
    HIRED = 'hired',
    REJECTED = 'rejected',
    WITHDRAWN = 'withdrawn',
    CUSTOM = 'custom'
}

export enum APPLICATION_STATUS_ICON {
    APPLIED = 'ti ti-clock text-primary text-xl mr-1',
    SHORTLISTED = 'ti ti-user-check text-primary text-xl mr-1',
    IN_REVIEW = 'ti ti-clock text-amber-400 text-xl mr-1',
    HIRED = 'ti ti-circle-check text-green-500 text-xl mr-1',
    REJECTED = 'ti ti-circle-x text-danger text-xl mr-1',
    WITHDRAWN = 'ti ti-user-x text-primary text-xl mr-1',
    CUSTOM = 'ti ti-navigation text-primary text-2xl mr-1'
}
