export class SearchModel {
    static DEFAULT_PAGE_SIZE: number = 10;
    static DEFAULT_PAGE_INDEX: number = 10;
    static CREATED_AT_FIELD: string = 'createdAt';
    static LAST_MODIFIED_AT_FIELD: string = 'lastModifiedAt';
    static ID_FIELD: string = 'id';
}

export class SearchOutDto<T> {
    data: T[];
    paginationOutDto?: PaginationOutDto;
    sortBy: string;
    direction: any;
    searchText: string;
}

export class SearchInDto {
    searchText?: string = "";
    pageIndex?: number = SearchModel.DEFAULT_PAGE_INDEX;
    pageSize?: number = SearchModel.DEFAULT_PAGE_SIZE;
    sortBy?: SORT_BY = SORT_BY.CREATED_AT;
    direction?: SORT_BY_DIRECTION = SORT_BY_DIRECTION.DESC;
    searchCriteriaDtos?: SearchCriteriaDto[];
}

export enum SORT_BY_DIRECTION {
    ASC = 'ASC',
    DESC = 'DESC'
}

export class PaginationOutDto {
    pageIndex: number = 0;
    pageSize: number = 20;
    totalElements: number;
    totalPages: number;

    constructor(pageIndex: number, pageSize: number) {
        this.pageIndex = pageIndex;
        this.pageSize = pageSize;
    }
}

export class SearchCriteriaDto {
    public fieldName?: string;
    public value: any;
    public operator: OPERATOR_DTO;
}

export enum SORT_BY {
    CREATED_AT = 'createdAt',
    LAST_MODIFIED_AT = 'lastModifiedAt',
    ID = 'ID'
}

export enum OPERATOR_DTO {
    in = 'in',
    like = 'like',
    lt = 'lt',
    gt = 'gt',
    eq = 'eq',
    text_search = 'text_search',
    not_like = 'not_like',
    not_in = 'not_in',
    exists = 'exists',
    not_exists = 'not_exists',
    not_eq = 'not_eq',
    between = 'between'
}
