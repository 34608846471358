import {DecodedLoginTokenDto, ExternalTokenDto} from "../../../home-layout/common/model/decoded-login-token.model";
import {jwtDecode} from "jwt-decode";

export class TokenUtil {

    static isTokenExpired(authToken: string): boolean {
        const expiryTime = this.getExpiryTime(authToken);
        if (expiryTime != null) {
            return ((1000 * expiryTime) - (new Date().getTime())) < 5000;
        } else {
            return false;
        }
    }

    static getExpiryTime(authToken: string): number {
        const decodedToken = this.decodeJwtToken(authToken);
        return decodedToken.exp;
    }

    static decodeJwtToken(accessToken: string): DecodedLoginTokenDto {
        return jwtDecode(accessToken);
    }

    static decodeTokenUsingAtoB(accessToken: string): ExternalTokenDto {
        const decodedTokenString = atob(accessToken);
        return this.mapToExternalTokenDto(decodedTokenString);

    }

    static mapToExternalTokenDto(decodedTokenString: string): ExternalTokenDto {
        const params = new URLSearchParams(decodedTokenString);
        const externalTokenDto = new ExternalTokenDto();

        externalTokenDto.fullName = params.get("full_name") || null;
        externalTokenDto.email = params.get("email_id") || null;
        externalTokenDto.externalId = params.get("user_unique_id") || null;
        externalTokenDto.contactNumber = params.get("phone_number") || null;

        return externalTokenDto;
    }
}
