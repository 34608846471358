import {Component, Inject, Input, OnInit} from '@angular/core';
import {BUTTON_SUB_TYPE, LibButtonComponent} from "../../../../tal-library/button/lib-button/lib-button.component";
import {NOTIFICATION_EVENT_TYPE, NotificationOutDto, NotificationService} from "../common/service/notification.service";
import {ROUTE} from "../../common/constant/routing.constant";
import {interval} from "rxjs";
import {RichTextSanitizerUtil} from "../common/util/rich-text-sanitizer.util";
import {DomSanitizer} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";
import {NotificationUtil} from '../common/util/notification.util';
import {DateUtil} from 'src/app/standout/common/util/date-util';
import {MatProgressBar} from "@angular/material/progress-bar";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'tal-notification-snackbar',
    standalone: true,
    imports: [
        LibButtonComponent,
        MatProgressBar
    ],
    templateUrl: './notification-snackbar.component.html',
    styleUrl: './notification-snackbar.component.scss',
    animations: [
        trigger('slideInAnimation', [
            // Enter transition - when element is inserted into DOM
            transition(':enter', [
                style({right: '-1000px', opacity: 0}), // Element starts outside the viewport
                animate('500ms ease-in-out', style({right: '0px', opacity: 1})) // Animates to the right position and fades in
            ]),
            // Optional leave transition - when element is removed from DOM
            transition(':leave', [
                animate('500ms ease-in-out', style({right: '-1000px', opacity: 0})) // Slide out and fade out
            ])
        ])
    ]
})
export class NotificationSnackbarComponent implements OnInit {

    @Input()
    notificationOutDto: NotificationOutDto = this.snackbarData?.data;

    progressValue = 100;
    remainingTime = 0;
    timeout = this.snackbarData?.timeout || 5000;
    protected readonly DateUtil = DateUtil;
    protected readonly NotificationUtil = NotificationUtil;
    protected readonly BUTTON_SUB_TYPE = BUTTON_SUB_TYPE;

    constructor(@Inject(MAT_SNACK_BAR_DATA) public snackbarData: any,
                private snackBarRef: MatSnackBarRef<NotificationSnackbarComponent>,
                private router: Router,
                private sanitizer: DomSanitizer,
                private notificationService: NotificationService) {
    }

    ngOnInit(): void {
        this.setNotificationTimeout();
    }

    sanitizeNotification(htmlString: string): string {
        return RichTextSanitizerUtil.sanitizeText(htmlString, this.sanitizer);
    }

    redirect(notificationOutDto: NotificationOutDto): void {
        switch (notificationOutDto?.notificationEventType) {
            case NOTIFICATION_EVENT_TYPE.APPLICANT_HIRING_ROUND_CHANGED,
                NOTIFICATION_EVENT_TYPE.APPLICANT_STATUS_CHANGED,
                NOTIFICATION_EVENT_TYPE.OFFER_SENT,
                NOTIFICATION_EVENT_TYPE.INVITED_BY_EMPLOYER:
                this.redirectForCandidate(notificationOutDto);
                break;
        }
    }

    private markAsRead(notificationOutDto: NotificationOutDto, userId: string): void {
        this.markNotificationAsRead(notificationOutDto, userId);
        this.snackBarRef.dismiss();
    }

    private markNotificationAsRead(notificationOutDto: NotificationOutDto, userId: string): void {
        this.notificationService.markOneAsRead(userId, notificationOutDto.id).then(() => {
        });
    }

    private setNotificationTimeout(): void {
        const timer$ = interval(100);
        const sub = timer$.subscribe((remainingTime) => {
            this.progressValue = 100 - remainingTime * 10000 / this.timeout;
            this.remainingTime = remainingTime;
            if (this.progressValue === 0) {
                sub.unsubscribe();
            }
        });
    }

    private redirectForCandidate(notificationOutDto: NotificationOutDto): void {
        this.markAsRead(notificationOutDto, notificationOutDto?.candidateId);
        this.redirectToCandidateJobPostDetailView(notificationOutDto);
    }

    private redirectToCandidateJobPostDetailView(notificationOutDto: NotificationOutDto): void {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(result => {
            this.router.navigate([ROUTE.JOBS, notificationOutDto?.jobPostId, ROUTE.JOB_DETAILS],
                {queryParams: {applicantId: notificationOutDto?.applicantId}});
        });
    }
}
