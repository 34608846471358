import {Component, Input} from '@angular/core';
import {
    BUTTON_SUB_TYPE,
    BUTTON_TYPE,
    LibButtonComponent
} from "../../../../../tal-library/button/lib-button/lib-button.component";
import {LibCircleScoreBarComponent} from "../../../../../tal-library/circle-score-bar/lib-circle-score-bar.component";
import {MatButton} from "@angular/material/button";
import {MatMenu} from "@angular/material/menu";
import {ResumeSelectorComponent} from "../../../common/component/resume-selector/resume-selector.component";
import {
    BUTTON_MULTI_LINE_TYPE,
    LibButtonMultiLineComponent
} from "../../../../../tal-library/button/lib-button-multi-line/lib-button-multi-line.component";
import {ROUTE} from "../../../common/constant/routing.constant";
import {ActivatedRoute, Router} from "@angular/router";
import {JobPostOutDto} from "../../common/service/job-post.service";
import {
    LibHeaderComponent
} from "../../../../../tal-library/header/lib-header/lib-header.component";

@Component({
    selector: 'tal-account-info',
    standalone: true,
    imports: [
        LibButtonComponent,
        LibCircleScoreBarComponent,
        MatButton,
        MatMenu,
        ResumeSelectorComponent,
        LibButtonMultiLineComponent,
        LibHeaderComponent
    ],
    templateUrl: './account-info.component.html',
    styleUrl: './account-info.component.scss'
})
export class AccountInfoComponent {

    @Input()
    jobPostOutDto: JobPostOutDto;

    protected readonly BUTTON_TYPE = BUTTON_TYPE;
    protected readonly BUTTON_SUB_TYPE = BUTTON_SUB_TYPE;
    protected readonly BUTTON_MULTI_LINE_TYPE = BUTTON_MULTI_LINE_TYPE;
    private jobId: string;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute) {
        this.setJobId();
    }

    navigateToLogin(): void {
        this.router.navigate([ROUTE.AUTH, ROUTE.LOGIN], {
            queryParams: {
                jobId: this.jobId,
            },
        });
    }

    onCardClick(): void {
        this.router.navigate([ROUTE.AUTH, ROUTE.SIGNUP_UPLOAD], {
            queryParams: {
                jobId: this.jobId,
            },
        });
    }

    private setJobId(): void {
        this.activatedRoute.params.subscribe(params => {
            const jobId = params[ROUTE.JOB_ID];
            if (jobId) {
                this.jobId = jobId;
            }
        });
    }
}
