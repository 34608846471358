import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ROUTE} from "../../../home-layout/common/constant/routing.constant";
import {
    LibSnackBarConfig,
    SNACK_BAR_TYPE
} from "../../../../tal-library/snackbar/lib-snackbar/common/model/lib-snackbar.model";
import {LibSnackbarService} from "../../../../tal-library/snackbar/lib-snackbar/common/service/lib-snackbar.service";
import {TokenUtil} from "../util/token.util";
import {LoginOutDto} from "../../../home-layout/common/model/user.model";

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    private redirectUrl: string;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private libSnackbarService: LibSnackbarService) {
    }

    handleLoginSuccess(loginOutDto: LoginOutDto, jobId?: string) {
        this.setRedirectUrlIfExists(jobId, () => {
            this.processAccessToken(loginOutDto);
            this.showSnackBar();
        });
    }

    handleSignupSuccess() {
        this.setRedirectUrlIfExists();
    }

    handle403(): void {
        // TODO : handle it in interceptor
        const token = localStorage.getItem(ROUTE.TOKEN);
        if (TokenUtil.isTokenExpired(token)) {
            this.tokenExpired();
        }
    }

    setAccessToken(accessToken: string): void {
        if (accessToken) {
            const token = 'Bearer ' + accessToken;
            this.saveToken(ROUTE.TOKEN, token);
        }
    }

    private setRedirectUrlIfExists(jobId?: string, callback?: any): void {
        this.redirectUrl = this.activatedRoute.snapshot.queryParamMap.get(ROUTE.REDIRECT_URL);
        if (this.redirectUrl) {
            this.router.navigate([this.redirectUrl.split('?')[0]]);
        } else if (jobId) {
            this.router.navigate([ROUTE.JOBS, jobId, ROUTE.JOB_DETAILS]);
        } else {
            this.router.navigate([ROUTE.DASHBOARD], {
                queryParamsHandling: 'merge'
            });
        }
        if (callback) callback();
    }

    private processAccessToken(loginOutDto: LoginOutDto): void {
        const token = loginOutDto?.jwtTokenDto?.accessToken;
        this.saveToken(ROUTE.TOKEN, token);
    }

    private showSnackBar(): void {
        this.libSnackbarService.openSnackBar(new LibSnackBarConfig('Welcome back', SNACK_BAR_TYPE.WELCOME));
    }

    private tokenExpired(): void {
        this.libSnackbarService.openSnackBar(new LibSnackBarConfig('Oops! Your login token is expired. Please login.',
            SNACK_BAR_TYPE.DANGER));
        this.logout();
    }

    private logout(): void {
        this.clearLocalStorageItems();
        this.redirectToLogin();
    }

    private clearLocalStorageItems(): void {
        localStorage.removeItem(ROUTE.TOKEN);
        localStorage.removeItem(ROUTE.COMPANY_ID);
        localStorage.clear();
    }

    private saveToken(tokenKey: string, tokenValue: string): void {
        localStorage.setItem(tokenKey, tokenValue);
    }

    private redirectToLogin() {
        this.router.navigate([ROUTE.AUTH, ROUTE.LOGIN]).then(() => {
            window.location.reload();
        });
    }
}
