import {Component, OnInit} from '@angular/core';
import {AccountInfoComponent} from '../../home-layout/job/job-apply/account-info/account-info.component';
import {LibFlatCardComponent} from '../../../tal-library/card/lib-flat-card/lib-flat-card.component';
import {LibStepperComponent} from '../../../tal-library/datasheet/lib-stepper/lib-stepper.component';
import {JobPostOutDto} from '../../home-layout/job/common/service/job-post.service';
import {LibStepperModel} from '../../../tal-library/datasheet/common/model/lib-stepper.model';
import {JOB_APPLY_STEP_NAME, JobApplyUtil} from '../../home-layout/job/common/util/job-apply.util';
import {ROUTE} from '../../home-layout/common/constant/routing.constant';
import {ActivatedRoute, Router} from '@angular/router';
import {PublicJobPostService} from './common/service/public-job-post.service';
import {TokenUtil} from '../common/util/token.util';
import {ApplicantInDto, ApplicantService} from '../../home-layout/job/common/service/applicant.service';
import {DecodedLoginTokenDto} from '../../home-layout/common/model/decoded-login-token.model';
import {AuthStore} from '../../home-layout/common/store/auth-store.service';
import {
    CandidateHasJobPostOutDto,
    CandidateHasJobPostSearchInDto,
    CandidateHasJobService
} from '../../home-layout/job/common/service/candidate-has-job.service';
import {
    LibCardSkeletonComponent,
    SKELETON_LAYOUT_TYPE
} from '../../../tal-library/skeleton/lib-card-skeleton/lib-card-skeleton.component';
import {environment} from '../../../../environments/environment';
import {APPLICANT_STATUS_TYPE} from '../../home-layout/job/common/service/candidate-has-job-application.service';

@Component({
    selector: 'tal-public-job-apply',
    standalone: true,
    imports: [
        AccountInfoComponent,
        LibFlatCardComponent,
        LibStepperComponent,
        LibCardSkeletonComponent
    ],
    templateUrl: './public-job-apply.component.html',
    styleUrl: './public-job-apply.component.scss'
})
export class PublicJobApplyComponent implements OnInit {
    jobId: string;
    jobPostOutDto: JobPostOutDto;
    libStepperModels: LibStepperModel[] = [];
    activeLibStepperModel: LibStepperModel;
    protected isLoading: boolean = false;
    protected readonly JOB_APPLY_STEP_NAME = JOB_APPLY_STEP_NAME;
    protected readonly SKELETON_LAYOUT_TYPE = SKELETON_LAYOUT_TYPE;
    private candidateId: string;

    constructor(private router: Router,
                private authStore: AuthStore,
                private activatedRoute: ActivatedRoute,
                private applicantService: ApplicantService,
                private candidateHasJobService: CandidateHasJobService,
                private publicJobPostService: PublicJobPostService) {
        this.setJobId();
    }

    ngOnInit(): void {
        this.libStepperModels = JobApplyUtil.getStepsForNonLoggedInUser();
        this.activeLibStepperModel = this.libStepperModels[0];
        this.setJobPostOutDto();
    }

    updatedActiveLibStepperModel(libStepperModel: LibStepperModel): void {
        this.activeLibStepperModel = libStepperModel;
    }

    getCareerSiteUrl(): string {
        return environment.showcaseBaseAppUrl + ROUTE.SLASH + ROUTE.E + ROUTE.SLASH + ROUTE.COMPANY + ROUTE.SLASH + this.getCompanyUrl();
    }

    getCompanyUrl(): string {
        return this.jobPostOutDto?.companyOutDto?.urlHandle;
    }

    private setJobPostOutDto(): void {
        this.isLoading = true;
        this.publicJobPostService.find(this.jobId).subscribe(jobPostOutDto => {
            this.jobPostOutDto = jobPostOutDto;
            this.checkForLoggedInUser();
        });
    }

    private checkForLoggedInUser(): void {
        const token = localStorage.getItem(ROUTE.TOKEN);
        if (token) {
            if (!TokenUtil.isTokenExpired(token)) {
                const decodedLoginTokenDto = TokenUtil.decodeJwtToken(token);
                this.setCandidateId(decodedLoginTokenDto);
                this.loadJobPostSearchOutDto(decodedLoginTokenDto);
            } else {
                this.authStore.logout();
                this.isLoading = false;
            }
        } else {
            this.isLoading = false;
        }
    }

    private loadJobPostSearchOutDto(decodedLoginTokenDto: DecodedLoginTokenDto): void {
        this.candidateHasJobService.search(this.candidateId, this.createCandidateHasJobPostSearchInDto())
            .subscribe(candidateHasJobPostOutDto => {
                this.navigateToApplyForLoggedInUser(decodedLoginTokenDto, candidateHasJobPostOutDto?.data[0]);
            });
    }


    private navigateToApplyForLoggedInUser(decodedLoginTokenDto: DecodedLoginTokenDto, candidateHasJobPostOutDto: CandidateHasJobPostOutDto): void {
        if (candidateHasJobPostOutDto?.applicantMinOutDto?.currentApplicantStatusOutDto) {
            candidateHasJobPostOutDto?.applicantMinOutDto?.currentApplicantStatusOutDto?.applicantStatusType === APPLICANT_STATUS_TYPE.APPLIED
                ? this.navigateToJobDetail(candidateHasJobPostOutDto?.applicantId)
                : this.navigateToJobApply(candidateHasJobPostOutDto?.applicantId);
        } else {
            this.createApplicant(decodedLoginTokenDto);
        }
    }

    private createApplicant(decodedLoginTokenDto: DecodedLoginTokenDto): void {
        this.applicantService.create(this.createApplicantInDto(decodedLoginTokenDto))
            .subscribe(applicantOutDto => {
                this.navigateToJobApply(applicantOutDto?.id);
            });
    }

    private navigateToJobApply(applicantId: string): void {
        this.router.navigate([ROUTE.JOBS, this.jobId, ROUTE.APPLY], {
            queryParams: {
                applicantId: applicantId,
            }
        });
    }

    private navigateToJobDetail(applicantId: string): void {
        this.router.navigate([ROUTE.JOBS, this.jobId, ROUTE.JOB_DETAILS], {
            queryParams: {
                applicantId: applicantId,
            }
        });
    }

    private createApplicantInDto(decodedLoginTokenDto: DecodedLoginTokenDto): ApplicantInDto {
        return {
            name: decodedLoginTokenDto?.userMinOutDto?.fullName,
            email: decodedLoginTokenDto?.userMinOutDto?.email ?? '',
            jobPostTitle: this.jobPostOutDto?.jobTitleOutDto?.title,
            phoneNumber: decodedLoginTokenDto?.userMinOutDto?.contactNumber ?? '',
            jobPostId: this.jobId,
            candidateId: decodedLoginTokenDto?.memberOutDto?.candidateId,
        } as ApplicantInDto;
    }

    private createCandidateHasJobPostSearchInDto(): CandidateHasJobPostSearchInDto {
        return {
            myApplication: true,
            jobPostIds: [this.jobId]
        } as CandidateHasJobPostSearchInDto;
    }

    private setCandidateId(decodedLoginTokenDto: DecodedLoginTokenDto): void {
        this.candidateId = decodedLoginTokenDto?.memberOutDto?.candidateId;
    }

    private setJobId(): void {
        this.activatedRoute.params.subscribe(params => {
            const jobId = params[ROUTE.JOB_ID];
            if (jobId) {
                this.jobId = jobId;
            }
        });
    }
}
