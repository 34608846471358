import {DomainUserOutDto, UserMinOutDto} from "./user.model";

export class DecodedLoginTokenDto {
    public auth: any;
    public userMinOutDto: UserMinOutDto;
    public memberOutDto?: DomainUserOutDto; // NOTE: kept purposefully
    public exp: any;
    public iat: any;
    public jti: any;
    public sub: any;
}

export class ExternalTokenDto {
    public fullName?: string;
    public email: string;
    public externalId?: string;
    public contactNumber?: string;
}
